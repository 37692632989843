<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Name -->
                  <b-col md="12">
                    <b-form-group
                      :label="$t('NAME')"
                      label-for="id-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-name"
                          v-model="task.name"
                          maxlength="100"
                          autofocus
                          :placeholder="$t('NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Referance ID -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('REFERENCE_ID')"
                      label-for="id-reference-number"
                    >
                      <b-form-input
                        id="id-reference-number"
                        v-model="task.externalReference"
                        maxlength="45"
                        :placeholder="$t('REFERENCE_ID')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Tasktype -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('TYPE')"
                      label-for="id-tasktype"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('TYPE')"
                        rules="required"
                      >
                        <v-select
                          id="id-tasktype"
                          v-model="taskType"
                          :placeholder="$t('TYPE')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="taskTypes"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Frequency -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('FREQUENCY')"
                      label-for="id-frequencyType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('FREQUENCY')"
                        rules="required"
                      >
                        <v-select
                          id="id-frequencyType"
                          v-model="taskFrequency"
                          :placeholder="$t('SELECT_FREQUENCY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="frequencies"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Points -->
                  <b-col md="6">

                    <b-form-group
                      :label="$t('POINTS')"
                      label-for="id-task-points"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('POINTS')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-task-points"
                          v-model="task.points"
                          maxlength="3"
                          type="number"
                          trim
                          :placeholder="$t('POINTS')"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>

                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--Sort order -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('SORT_ORDER')"
                      label-for="id-sequence"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('SORT_ORDER')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-sequence"
                          v-model="task.sequence"
                          maxlength="3"
                          type="number"
                          :placeholder="$t('SORT_ORDER')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--charity Amount-->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('CHARITY_AMOUNT')"
                      label-for="id-charityAmount"
                    >
                      <b-form-input
                        id="id-charityAmount"
                        v-model="task.charityWeight"
                        type="number"
                        :placeholder="$t('CHARITY_AMOUNT')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Description -->
                  <b-col md="12">
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="id-description"
                    >
                      <quill-editor
                        id="id-description"
                        v-model="task.description"
                        maxlength="100"
                        :options="editorOption"
                        class="border-bottom-0"
                      />
                      <div
                        id="quill-toolbar"
                        class="d-flex justify-content-end border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ task.id ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="navigateToList"
                    >
                      {{ $t("BUTTON.CANCEL") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import mixinAlert from '@/constants/mixinAlert'
import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  data() {
    return {
      content: useJwt.getContent() || {},
      task: {
        id: 0,
        contentId: 0,
        sequence: 0,
        taskTypeId: 0,
        taskFrequencyId: 0,
        name: '',
        description: '',
        externalReference: '',
        points: 0,
        charityWeight: 0,
      },
      taskType: '',
      taskFrequency: '',
      required,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your task description',
    }

    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
      editorOption,
    }
  },
  computed: {
    ...mapState('task', {
      taskTypes: state => state.allTaskTypes,
      frequencies: state => state.allTaskFrequencies,
    }),
  },
  watch: {
    taskType: {
      handler(newValue) {
        this.task.taskTypeId = newValue ? newValue.id : null
      },
      immediate: true,
    },
    taskFrequency: {
      handler(newValue) {
        this.task.taskFrequencyId = newValue ? newValue.id : null
      },
      immediate: true,
    },
  },
  async created() {
    await this.fetchTaskTypes()
    await this.fetchTaskFrequencies()
    if (router.currentRoute.params.id) {
      this.fetchTaskByID(router.currentRoute.params.id).then(response => {
        if (response && response.data) {
          this.task = response.data
          // Set initial selection objects based on IDs
          this.taskType = this.taskTypes.find(type => type.id === this.task.taskTypeId) || null
          this.taskFrequency = this.frequencies.find(freq => freq.id === this.task.taskFrequencyId) || null
        }
      })
    }
  },
  methods: {
    ...mapActions('task', [
      'createTask',
      'updateTask',
      'fetchTaskByID',
      'fetchTaskFrequencies',
      'fetchTaskTypes']),
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          const payload = { ...this.task, contentId: this.content.id }
          const saveOperation = this.task.id ? this.updateTask : this.createTask
          saveOperation(payload)
            .then(response => {
              if (response) {
                const messageKey = this.task.id ? 'MESSAGE.TASK_UPDATED' : 'MESSAGE.TASK_CREATED'
                this.successMessage(this.$i18n.t(messageKey))
                this.$router.push({ name: 'task-list' })
              }
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    navigateToList() {
      this.$router.push({ name: 'task-list' })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/quill.scss';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

</style>
